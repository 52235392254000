import * as React from 'react';
import { graphql } from 'gatsby';
import PostCardMinimal from '../components/post-card-minimal/post-card-minimal';
import Pagination from '../components/pagination/pagination';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogPostsWrapper } from './templates.style';

const BlogList: React.FC = (props: any) => {
  const { data } = props;
  const Posts = data.posts.edges;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/page/1' : `/page/${(currentPage - 1).toString()}`;
  const nextPage = `/page/${(currentPage + 1).toString()}`;
  const PrevLink = !isFirst && prevPage;
  const NextLink = !isLast && nextPage;

  return (
    <Layout>
      <SEO title={`Page ${currentPage}`} />

      <BlogPostsWrapper>
        {Posts.map(({ node }: any) => {
          const tags = node.article_tags.map((article_tag: {tag_name: string}) => article_tag.tag_name);
          
          return (
            <PostCardMinimal
              key={node.slug}
              title={node.title || node.slug}
              image={
                node.coverImage == null
                  ? null
                  : node.coverImage.childImageSharp.fluid
              }
              url={`/${node.slug}`}
              description={node.description }
              date={node.published_date}
              tags={tags}
            />
          );
        })}

        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    posts: allStrapiArticle(
      limit: $limit
      skip: $skip
      sort: { fields: [published_date], order: DESC }
    ) {
      edges {
        node {					
          title
          slug
          description
          published_date(formatString: "DD [<span>] MMMM [</span>]")
          article_tags {
            tag_name
          }
          coverImage {
            id
            childImageSharp {
              fluid(maxWidth: 170, maxHeight: 170, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

